<template>
	<div :class= "mainNavIsHidden ? 'main-nav-hidden': ''">
		<h2  class="black-text-color">{{ languageStrings.playRecords }}</h2>
		<table class="play-records encore-background curved-border">
		<table class="play-records balances second-table-container">
			<thead>
			<tr class="header-row">
				<th>{{ languageStrings.resolvedTime }}</th>
				<th>{{ languageStrings.totalWinAU }}</th>
			</tr>
		</thead>
		<tbody  v-for="(item, index) in playRecords" :class="expandedRowPresent ? checkForExpandedRows(index) ? 'selected open':'not-selected' : 'selected'" :key="index" @click="setOpenClass(index)" :id="`row-${index}`" >
			<tr class="data-row curved-border">
					<td class="encore-color">
						{{ new Date(item.resolvedTime).toLocaleTimeString(this.localeString, dateOptions) }}
					</td>
					<td class="encore-color">
						{{
						systemCurrencyTool.formatFromAU(
								item.totalWinAU,
								systemCurrencyTool.displayType.minorOrFull
							)
						}}
						<i class="arrow down"></i> 
					</td>
				</tr>
				<tr class="panel">
					<td class="expandable-data-table-parent-td-firefox" colspan="2">
						<table class="expandable-data-table">
							<thead>
								<th class="inner-table-header">{{ languageStrings.playRecordID }}</th>
								<th class="inner-table-header">{{ languageStrings.gameName }}</th>
								<th class="inner-table-header">{{ languageStrings.totalWagerAU }}</th>
							</thead>
							<tr class="position-expandable-data">
								<td class="expandable-data-table-td-styling-firefox"> {{ item.playRecordId }}</td>
								<td>{{ item.gameTitle }}</td>
								<td class="expandable-data-table-td-styling-firefox">
									{{
						systemCurrencyTool.formatFromAU(
								item.totalWagerAU,
								systemCurrencyTool.displayType.minorOrFull)
									}}
								</td>
							</tr>
						</table>
					</td>
				</tr>
		</tbody>
	</table>
	</table>
	<div class="pagination-div">
	<div class="encore-style-button-container">
			<button :disabled="serverBusy" class="btn encore-style-button curved-border player-record-buttons" type="button" :title="languageStrings.previous" @click="previousPage()">
				{{ languageStrings.previous }}
			</button>
	</div>
		<div class="limit-options-section">
			<label class="encore-color" for="limitOptions">{{ languageStrings.limitList }}</label>
				<select :title="languageStrings.setLimit" v-model="limit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
		</div>
		<div class="encore-style-button-container">
			<button :disabled="serverBusy" class="btn encore-style-button curved-border player-record-buttons" type="button" :title="languageStrings.next" @click="nextPage()">
				{{ languageStrings.next }}
			</button>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: "PlayRecords",
	props: {
		systemSettings: Object,
		playerState: Object,
		isMobile: Boolean,
		userProfile: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		mainNavIsHidden: Boolean,
		systemCurrencyTool: Object,
		mainNavIsHidden: Boolean,
	},
	data() {
		return {
			limitOptions: [
				{ text: "5", value: 5 },
				{ text: "10", value: 10 },
				{ text: "20", value: 20 },
				{ text: "50", value: 50 },
				{ text: "100", value: 100 },
				{ text: "250", value: 250 },
				{ text: "500", value: 500 },
			],
			playRecords: [],
			expandedRows:[],
			expandedRowPresent: false,
			limit: 20,
			offset: 0,
			serverBusy: false,
			busyText: "",
			isLastPage: false,
			currentPage: 1,
		}
	},
	watch: {
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.getUserPlayRecords();
		}
	},
	created() {
		this.getUserPlayRecords();
	},

	methods: {
		async getUserPlayRecords() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.gettingPlayRecords;

			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState?.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`api/v1/play/listarchived`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;
			params.set("offset", this.offset);
			params.set("limit", this.limit);
			
			let today = new Date(Date.now());
			let year =today.toLocaleString("default", { year: "numeric" });
			let month = today.toLocaleString("default", { month: "2-digit" });
			let day = today.toLocaleString("default", { day: "2-digit" });
			
			params.set("startDate", year-5 +"-"+month+"-"+day);
			params.set("enDate", year+"-"+month+"-"+day);
			
			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);
				this.playRecords = await response.json();
				this.serverBusy = false;
				this.busyText = "";
			}
			catch (e){
				this.serverBusy = false;
				this.busyText = "";
			}
		},

		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.getUserPlayRecords();
		},

		nextPage() {
			if (this.isLastPage || this.playRecords.length < this.limit) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.getUserPlayRecords();
		},

		setOpenClass(index) {
			var currentRow = document.getElementById(`row-${index}`);
			currentRow.classList.contains("open") ?  (currentRow.classList.remove("open"), currentRow.classList.remove("selected"), currentRow.classList.add("not-selected")) :
			 (currentRow.classList.add("open"), currentRow.classList.add("selected"), currentRow.classList.remove("not-selected"));
			this.checkIfThereAreExpandedRows();
			
		},
		checkForExpandedRows(index) {
			return this.expandedRows.includes(index);
		},
		checkIfThereAreExpandedRows() {
			let foundExpandedRow = false;
			this.playRecords.forEach((element,index) => {
				var currentRow = document.getElementById(`row-${index}`);
				if(currentRow?.classList.contains("open")){
					this.expandedRowPresent = true;
					foundExpandedRow = true;
					this.expandedRows.push(index);
				}
			});

			if(!foundExpandedRow){
				this.expandedRows = [];
				this.expandedRowPresent = false;
			}
		}
	}
}
</script>

<style scoped>

.player-record-button {
	padding: 5px;
}

.limit-options-section {
	color: black;
	display: flex;
	align-items: center;
	padding: 20px;
}
.pagination-div {
	display: flex;
	margin-top: 30px;
	align-items: center;
}

.panel {
	display: none;
	opacity:50%;
	height: 105px;
	background-color: white;
}

.open .panel {
	display: table-row;
	opacity: 100%;
}

.expandable-data-table {
	width: 100%;
	color: #6305C9;
	background-color: white;
}

.play-records {
	position: relative;
	width: 95%;
	margin: auto;
	border-collapse: separate;
	text-align: center;
	/* background-color: #d7d7d7; */
	/* box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%); */
	border-spacing: 0 0.7em;
	max-width: 1100px;
}

table {
	width: 90%;
}

.black-text-color {
	color: black;
}

tr td:first-of-type {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-left: 10px;
  /* -moz-border-style: solid; */
}

@-moz-document url-prefix() {
	tr td:first-of-type {
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		border: 0px solid;
		border-collapse: separate;
		background-color: white;
		border-spacing: 0 0.7em;
	}
	tr td:last-of-type {
  		border-top-right-radius: 15px;
  		border-bottom-right-radius: 15px;
		border: 0px solid;
		border-collapse: separate;
		background-color: white;
		border-spacing: 0 0.7em;
	}

	.play-records tbody {
		background-color: transparent !important;
	}
	
	.expandable-data-table-td-styling-firefox {
		background-color: black !important;
	}

	.expandable-data-table-parent-td-firefox {
		background-color: black !important;
	}	
}

tr td:last-of-type {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  /* -moz-border-style: solid; */
}


.data-row {
	height: 37px;
}
tr {
	font-weight: bold;
	padding: 10px;
}

.play-records tbody {
	background-color: white;
}

.encore-style-button-container {
	background-color:  #6305C9;
}

.encore-style-button {
	color: white !important;
	-webkit-text-fill-color: white;
}

.main-nav-hidden {
	margin-top: 117px;
}

@media(max-width: 767px) {

	.expandable-data-table {
		background-color: inherit;
	}

	.inner-table-header{
		background-color: white !important;
	}
}
</style>